<template>
  <!-- eslint-disable  max-len -->
  <div class="second-stage-wrapper">
    <div class="second-stage-wrapper--headers">
      <p class="grow-12">
        {{ $t('super_admin_js.modals.new_integration.second_stage.title') }}
      </p>
      <p class="grow-9">
        {{ $t('super_admin_js.modals.new_integration.unit') }}
      </p>
      <p class="grow-1">
        {{ $t('super_admin_js.modals.new_integration.code') }}
      </p>
      <p>{{ $t('super_admin_js.modals.new_integration.active') }}</p>
      <p>{{ $t('super_admin_js.modals.new_integration.as_evp_label') }}</p>
    </div>
    <div
      v-for="(abs, index) in absWithoutLateness"
      :key="`without_lateness_${index}`"
      class="second-stage-wrapper--abs-line"
    >
      <integration-text
        v-model="abs.absence_key"
        :class="!abs.active ? 'inactive' : ''"
        :text="abs.name"
        :width="250"
      />
      <integration-select
        v-model="abs.unit"
        :class="!abs.active ? 'inactive' : ''"
        :options="unitOptions"
        :width="150"
        :label="$t('super_admin_js.modals.new_integration.second_stage.time_unit')"
      />
      <integration-input
        v-model="abs.code"
        :class="!abs.active ? 'inactive' : ''"
        :width="80"
        :label="$t('super_admin_js.modals.new_integration.second_stage.code')"
      />
      <div class="sk-switch-inline">
        <WhiteSkSwitch
          v-model="abs.active"
          :value="abs.active"
          :field="`absence_${abs.id}`"
          :model="abs"
          :modelAttribute="abs.active"
          :changeAction="activateAbsence"
          name="active"
        />
      </div>
      <div class="sk-switch-inline">
        <WhiteSkSwitch
          v-model="abs.as_evp"
          :disabled="!abs.active"
        />
      </div>
    </div>
    <div
      v-for="(abs, index) in absOnlyLateness"
      :key="`only-lateness-${index}`"
      class="second-stage-wrapper--abs-line"
    >
      <integration-text
        v-model="abs.absence_key"
        :class="!abs.active ? 'inactive' : ''"
        :text="abs.name"
        :width="250"
      />
      <integration-select
        v-model="abs.unit"
        :class="!abs.active ? 'inactive' : ''"
        :options="latenessUnitOptions"
        :width="150"
        :label="$t('super_admin_js.modals.new_integration.second_stage.time_unit')"
      />
      <integration-input
        v-model="abs.code"
        :class="!abs.active ? 'inactive' : ''"
        :width="80"
        :label="$t('super_admin_js.modals.new_integration.second_stage.code')"
      />
      <div class="sk-switch-inline">
        <WhiteSkSwitch
          v-model="abs.active"
          :value="abs.active"
          :field="`absence_${abs.id}`"
          :model="abs"
          :modelAttribute="abs.active"
          :changeAction="activateAbsence"
          name="active"
        />
      </div>
      <div class="sk-switch-inline">
        <WhiteSkSwitch
          v-model="abs.as_evp"
          :disabled="!abs.active"
        />
      </div>
    </div>
  </div>
</template>

<script>
import IntegrationSelect from '../IntegrationSelect';
import IntegrationInput from '../IntegrationInput';
import IntegrationText from '../IntegrationText';
import WhiteSkSwitch from '../../../WhiteSkSwitch';

export default {
  name: 'NewIntegrationModalSecondStage',
  components: {
    IntegrationSelect,
    IntegrationInput,
    IntegrationText,
    WhiteSkSwitch,
  },
  mixins: [],
  props: {
    shopId: {
      type: Number,
      required: true,
    },
    integration: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    unitOptions() {
      if (this.integration.payroll_software === 'Quadratus') {
        return [
          { key: 'days', value: this.$t('super_admin_js.modals.new_integration.second_stage.days') },
          { key: 'hours', value: this.$t('super_admin_js.modals.new_integration.second_stage.hours_and_days') },
        ];
      }
      return [
        { key: 'hours', value: this.$t('super_admin_js.modals.new_integration.second_stage.hours') },
        { key: 'days', value: this.$t('super_admin_js.modals.new_integration.second_stage.days') },
      ];
    },
    latenessUnitOptions() {
      if (this.integration.payroll_software === 'Quadratus') {
        return [{ key: 'hours', value: this.$t('super_admin_js.modals.new_integration.second_stage.hours_and_days') }];
      }
      return [{ key: 'hours', value: this.$t('super_admin_js.modals.new_integration.second_stage.hours') }];
    },
    absWithoutLateness() {
      return _.filter(this.integration.absences, (abs) => abs.name !== 'Retard');
    },
    absOnlyLateness() {
      return _.filter(this.integration.absences, (abs) => abs.name === 'Retard');
    },
  },
  methods: {
    activateAbsence(abs) {
      abs.active = !abs.active;
    },
  },
};
</script>

<style lang="scss" scoped>
.second-stage-wrapper {
  padding: 30px;
  max-height: 330px;
}

.second-stage-wrapper--headers {
  display: flex;
  justify-content: space-around;

  p {
    color: $sk-gray-med-2;
  }

  .grow-12 {
    flex-grow: 12;
  }

  .grow-9 {
    flex-grow: 9;
  }

  .grow-1 {
    flex-grow: 1;
  }
}

.inactive {
  opacity: .4;
}

.second-stage-wrapper--abs-line {
  display: flex;
}

.sk-switch-inline {
  height: 30px;
  margin: auto;
  padding-top: 2px;
}
</style>
